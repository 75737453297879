<template>
	<div>
		<div class="pt-7 container-fluid">
			<b-card class="mb-4">
				<b-row class="filterSection">
					<b-col md="12" class="mb-4">
						<b-form role="form" @submit.prevent="addItem" v-loading="loading">
							<b-input-group>
								<b-form-textarea
									v-model="nameList"
									size="sm"
									max-rows="8"
									placeholder="Hastalıkları alt alta girebilirsiniz"
								></b-form-textarea>
								<template #append>
									<b-button type="submit" :disabled="loading" variant="primary">Ekle</b-button>
								</template>
							</b-input-group>
						</b-form>
					</b-col>
				</b-row>

				<b-form-input v-model="filter.keyword" type="text" debounce="500" placeholder="Arama"></b-form-input>
			</b-card>

			<b-card v-loading="loading" v-if="records" class="p-4">
				<b-list-group>
					<b-list-group-item v-for="(item, index) in records" :key="index">
						<b-row>
							<b-col md="2">
								<b-button size="sm" pill variant="danger" @click="deleteItem(item._id)"><i class="fal fa-trash-alt"></i></b-button>
								<b-button size="sm" pill variant="primary" @click="editItem(item._id)"><i class="fal fa-edit"></i></b-button>
							</b-col>
							<b-col md="10">
								{{ getLangItem(item.name) }}
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
				<hr />
				<b-pagination v-model="currentPage" :total-rows="stats.totalRecord" :per-page="20"></b-pagination>
			</b-card>
		</div>
		<b-modal id="editmodal">
			<b-form role="form" @submit.prevent="saveItem">
				<b-input-group>
					<language-input style="width: 100%" v-model="record.name" />
					<b-button type="submit" variant="primary" style="position: absolute; left: -9999px">Submit</b-button>
				</b-input-group>
			</b-form>
			<template #modal-footer="{hide}">
				<div class="w-100">
					<b-button variant="primary" size="sm" class="float-right" @click="saveItem"> {{ $t('general.save') }} </b-button>
					<b-button size="sm" variant="danger" @click="hide('editmodal')"> {{ $t('general.cancel') }} </b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash'
import {handleError} from '@/utils/func'
import {mapGetters, mapState} from 'vuex'

export default {
	computed: {
		...mapState('disease', ['records', 'record', 'filter', 'stats', 'page']),
		currentPage: {
			get() {
				return _.clone(this.page)
			},
			set(val) {
				this.$store.commit('disease/setPage', val)
			}
		}
	},
	data() {
		return {
			loading: false,
			nameList: ''
		}
	},
	methods: {
		async getData() {
			await this.$store.dispatch('disease/list')
		},
		async addItem() {
			const result = await this.$store.dispatch('disease/create', this.nameList)
			this.$notify({type: 'success', message: `${result.added} öğe eklendi. ${result.notadded} öğe eklenemedi`})
		},
		async editItem(_id) {
			await this.$store.dispatch('disease/get', _id)
			this.$bvModal.show('editmodal')
		},
		async saveItem() {
			await this.$store.dispatch('disease/update')
			this.$bvModal.hide('editmodal')
			this.$notify({type: 'success', message: this.$i18n.t('success.positive')})
		},
		async deleteItem(_id) {
			const result = await this.$swal({
				title: this.$i18n.t('quest.sure'),
				text: this.$i18n.t('quest.delete_info'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#f5365c',
				confirmButtonText: this.$i18n.t('quest.delete'),
				cancelButtonText: this.$i18n.t('quest.cancel')
			})
			if (result.value) {
				this.loading = true
				try {
					await this.$store.dispatch('disease/delete', _id)
					this.loading = false
					this.$notify({type: 'success', message: this.$i18n.t('success.positive')})
				} catch (err) {
					this.loading = false
					handleError(this, err)
				}
			}
		}
	},
	async created() {
		await this.$store.dispatch('disease/list')
	},

	watch: {
		filter: {
			deep: true,
			handler: _.debounce(function (newVal) {
				this.$store.dispatch('disease/list')
			}, 500)
		},
		page() {
			this.$store.dispatch('disease/list')
		}
	}
}
</script>
